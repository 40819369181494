/* Page Container */
.page-container {
  clear: both;
}

.page-head {
  background: #fff;

  .container {
    position: relative;
  }

  .page-title {
    display: inline-block;
    float: left;
    padding: rem(19px) 0;

    > h1 {
      color: #697882;
      font-size: $font-size-large;
      font-weight: 400;
      margin: 0;

      > small {
        color: #9eacb4;
        font-size: $font-size-small;
        font-weight: 400;
      }
    }
  }

  $page-breadcrumb-toolbar-main-color: lighten(#eff2f4, 3%);

  .page-toolbar {
    display: inline-block;
    float: right;
  }
}

.breadcrumb {
  background: none;
  padding: 0 0 rem(15px) 0;
  margin: 0;
  color: #c5ccd5;
}

.breadcrumb > li + li:before {
  display: none;
}
.breadcrumb .fa {
  font-size: rem(6px);
  margin: 0 rem(2px) 0 rem(4px);
  position: relative;
  top: -1px;
}
.breadcrumb > .active {
  color: #9eacb4;
}

.page-content {
  background: $page-content-bg-color;
  padding: 140px 0 rem(15px);
}

@media (max-width: $screen-sm-max) {
  /* 991px */

  .page-head {
    background: #eff3f8;

    @include reset-container();
  }

  .page-content {
    padding: rem(90px) 0 rem(15px);
    @include reset-container();
  }
}
