//##  Default Layout Settings
//
// Define layout element width and height.


// Header
$header-height:             				            				126px  !default; // header height on table and desktop view. default: 42px
$header-height-xs:             											($header-height * 2) !default; // header height on mobile view
$page-header-top-height:												75px;

$header-logo-height:                                    				16px;
$header-logo-container-width:                                           255px;
$header-menu-toggler-width:                             				22px; 
$header-menu-toggler-height:                            				14px;
//$header-responsive-toggler-margin:                      				($header-height - $header-menu-toggler-height) / 2 6px 0 6px !default; 
$header-logo-margin:                                					($page-header-top-height - $header-logo-height) / 2 0 0 0 !default;  

$header-top-menu-general-item-padding:      							17px 10px 8px 10px !default;  // adjust top menu general elements padding. default: 15px 10px 7px 10px
$header-top-menu-general-item-padding-on-mobile:       					17px 6px 8px 6px !default;  // adjust top menu general elements padding mobile. default: 15px 10px 7px 10px

$header-top-menu-inbox-item-padding:      								17px 0px 8px 8px !default;  // adjust top menu general elements padding. default: 15px 10px 7px 10px
$header-top-menu-inbox-item-padding-on-mobile:       					17px 0px 8px 2px !default;  // adjust top menu general elements padding mobile. default: 15px 10px 7px 10px

$header-top-menu-user-item-padding:         							12px 6px 7px 6px !default; // adjust top menu user bar element padding. default: 6px 0px 6px 6px
$header-top-menu-user-item-padding-on-mobile:         					12px 0px 7px 4px !default;  // adjust top menu user bar element padding on mobile. default: 6px 0px 6px 4px

$header-top-menu-language-item-padding:     							16px 10px 9px 2px !default;  // adjust top menu language bar element padding. default: 11px 1px 11px 5px
$header-top-menu-language-item-padding-on-mobile:						16px 6px 9px 2px !default;  // adjust top menu language bar element padding. default: 11px 0px 11px 4px

$header-top-menu-general-item-badge-top:                				9px !default;  // adjust top menu general elements badge top position. default: 8px
$header-top-menu-icon-font-size:                                        rem(19px) !default;

$header-top-menu-user-font-size:                                        rem(13px) !default;
$header-top-menu-user-font-weight:                                      300 !default;   
$header-top-menu-user-dropdown-link-font-size:                          rem(14px) !default;
$header-top-menu-user-dropdown-link-font-weight:                        300 !default; 

$header-hor-menu-height:     											51px; 
$header-hor-menu-general-item-padding:     								16px 18px 10px 18px !default;  // adjust hor menu elements padding top. default: 11px 10px
$header-hor-menu-submenu-item-padding: 				 					10px 12px !default;  // adjust hor menu elements padding top. default: 7px 10px
$header-hor-menu-submenu-item-arrow-top:                				9px;  // adjust hor menu dropdown menu's arrow. default: 9px     
$header-hor-menu-search-toggler-button-padding:      					15px 22px 15px 22px !default;  // adjust hor menu elements padding top. default: 13px 22px 11px 22px
$header-hor-menu-search-button-padding:     		 					7px 20px !default;  // adjust hor menu elements padding top. default: 5px 20px
$header-hor-menu-search-input-margin:    			 					7px 0 0 0 !default;  // adjust hor menu elements padding top. default: 5px 0 0 0

// Horizontal menu font
$header-hor-menu-default-link-font-size:                                rem(14px) !default;
$header-hor-menu-default-link-font-weight:                              bold !default;
$header-hor-menu-dropdown-menu-link-font-size:                          14px !default; 
$header-hor-menu-dropdown-menu-link-font-weight:                        300 !default;
$header-hor-menu-mega-menu-header-font-size:                            rem(15px) !default;
$header-hor-menu-mega-menu-header-font-weight:                          bold !default;

// Page content
$page-content-bg-color:                   								#eff3f8;

// Z-index master list
$zindex-header-fixed:                                                   9995 !default;
$zindex-header-static:                                                  9995 !default;
$zindex-header-top-menu:                                                9996 !default;
$zindex-header-dropdown:                                                9997 !default;
$zindex-footer-fixed:      												10000 !default;
$zindex-go-to-top:   												    10001 !default;

//##  Direction
//
// Scripting direction

$direction:  						ltr !default;

// Bootstrap Components states

$state-default-text:             	#333333 !default;
$state-default-bg:           	 	$brand-default !default;
$state-default-border:       	 	darken(adjust-hue($state-default-bg, -10), 5%) !default;

$state-primary-text:          	 	#D8E3F2 !default;
$state-primary-bg:        		 	$brand-primary !default;
$state-primary-border:    	 	 	darken(adjust-hue($state-primary-bg, -10), 5%) !default;

$state-success-text:             	#3c763d !default;
$state-success-bg:               	#dff0d8 !default;
$state-success-border:           	darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                	#31708f !default;
$state-info-bg:                  	#d9edf7 !default;
$state-info-border:              	darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             	#8a6d3b !default;
$state-warning-bg:               	#fcf8e3 !default;
$state-warning-border:           	darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              	#a94442 !default;
$state-danger-bg:                	#f2dede !default;
$state-danger-border:            	darken(adjust-hue($state-danger-bg, -10), 5%) !default;

// Bootstrap Buttons

$btn-font-weight:                	normal !default;

$btn-default-color:              	#000 !default;
$btn-default-bg:                 	#fff !default; //$brand-default !default;
$btn-default-border:             	darken($btn-default-bg, 10%) !default;

$btn-primary-color:              	$brand-primary-color !default;
$btn-primary-bg:                 	$brand-primary !default;
$btn-primary-border:             	darken($btn-primary-bg, 10%) !default;

$btn-success-color:              	$brand-success-color !default;
$btn-success-bg:                 	$brand-success !default;
$btn-success-border:             	darken($btn-success-bg, 20%) !default;

$btn-info-color:                	$brand-info-color !default;
$btn-info-bg:                    	$brand-info !default;
$btn-info-border:                	darken($btn-info-bg, 10%) !default;

$btn-warning-color:              	$brand-warning-color !default;
$btn-warning-bg:                 	lighten($brand-warning, 20%) !default;
$btn-warning-border:             	darken($btn-warning-bg, 10%) !default;

$btn-danger-color:               	$brand-danger-color !default;
$btn-danger-bg:                  	$brand-danger !default;
$btn-danger-border:              	darken($btn-danger-bg, 10%) !default;

$btn-link-disabled-color:        	#eee !default;

//##  Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-primary:  	  		 	"Open Sans", sans-serif !default;
$font-family-default:  	  		 	"Helvetica Neue", Helvetica, Arial, sans-serif !default;

$font-size-base:          		 	14px;
$font-size-normal:        		 	rem($font-size-base) !default;
$font-size-medium:         		 	rem($font-size-base * 1.25) !default; // ~18px
$font-size-large:         		 	rem($font-size-base * 1.5) !default; // ~21px
$font-size-x-large:         		rem($font-size-base * 2) !default; // ~28px
$font-size-small:         		 	rem($font-size-base * 0.9) !default; // ~12px
$font-size-x-small:         		rem($font-size-base * 0.7) !default; // ~10px

$icon-size-base:          		 	$rem-baseline; // 16px
$icon-size-normal:        		 	rem($icon-size-base) !default;
$icon-size-medium:         		 	rem($icon-size-base * 1.25) !default; // ~18px
$icon-size-large:         		 	rem($icon-size-base * 1.5) !default; // ~21px
$icon-size-x-large:         		rem($icon-size-base * 2) !default; // ~28px
$icon-size-small:         		 	rem($icon-size-base * 0.85) !default; // ~12px

$font-size-h1:            		 	rem($font-size-base * 2.2) !default; // ~36px
$font-size-h2:            			rem($font-size-base * 2) !default; // ~30px
$font-size-h3:            			rem($font-size-base * 1.7) !default; // ~24px
$font-size-h4:            			rem($font-size-base * 1.25) !default; // ~18px
$font-size-h5:            			rem($font-size-base) !default;
$font-size-h6:            			rem($font-size-base * 0.9) !default; // ~12px

// Global text color on <body>
$text-color:            			#333333 !default;

// Global light text color on <body>
$text-light-color:     	 			#ffffff !default;

// Global muted text color on <body>
$text-muted-color:      			#444444 !default;

// Global textual link color.
$link-color:            			rgb(60, 100, 169) !default;

// Link hover color.
$link-hover-color:      			darken($link-color, 15%) !default;

$primary-link-color:                #65A0D0;

//== Forms Controls
$input-md-label-color:        		#888888;
$input-md-input-border:        		#e5e5e5;
$input-md-label-opacity:      		1;
$input-md-label-font-size: 			13px;
$input-md-placeholder:	 			#999;
$input-md-color:		 			#333;
$input-md-focus-border:				$brand-success;
$input-md-checkbox-box-color:  		#666;
$input-md-checkbox-disabled-opacity:0.7;

$input-bg:                       	#fff !default;
$input-bg-disabled:              	#eeeeee !default;

$input-color:                    	#333 !default;
$input-border:                   	#e5e5e5 !default;
$input-border-focus:             	#999 !default;

$input-color-placeholder:        	lighten($input-color, 50%) !default;
$input-icon-color:               	#999 !default;

//## Border radius
$general-border-radius:             4px !default;
$form-control-border-radius:        4px !default;
$portlet-border-radius:             4px !default;


//## Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            			9995 !default;
$zindex-navbar-above:      			9999 !default;
$zindex-dropdown:          			1000 !default;
$zindex-popover:           			1010 !default;
$zindex-tooltip:           			1030 !default;
$zindex-navbar-fixed:      			1030 !default;
$zindex-modal-background:  			10049 !default;
$zindex-modal:             			10050 !default;
$zindex-top:						10100 !default;

//## Media queries breakpoints
//
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              		480px !default;

// Small screen / tablet
$screen-sm-min:              		768px !default;

// Medium screen / desktop
$screen-md-min:              		992px !default;

// Large screen / wide desktop
$screen-lg-min:             	 	1200px !default;

// Large scree / wider desktop
$screen-lg-med:                     1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              		($screen-sm-min - 1) !default;
$screen-sm-max:              		($screen-md-min - 1) !default;
$screen-md-max:              		($screen-lg-min - 1) !default;

//## Img path settings

$general-img-path:                  '../img/';

//shadows

$block-shadow:                      0 3px 1px 0 rgba(0,0,0,0.2);

// Toastr
$toastr-successColor: #3c763d;
$toastr-successBgColor: #dff0d8;
$toastr-infoColor: #31708f;
$toastr-infoBgColor: #d9edf7;
$toastr-warningColor: #8a6d3b;
$toastr-warningBgColor: #fcf8e3;
$toastr-errorColor: #a94442;
$toastr-errorBgColor: #f2dede;

// Body and header
$bg-color: $page-content-bg-color;
$header-bg-color: #fff;
$header-menu-bg-color: #fff;

// Mega Menu
$header-hor-menu-bg-color: #fff;
$header-hor-menu-fixed-border-box: 0px 1px 10px 0px
rgba($header-hor-menu-bg-color, 0.2);

// Default Mega Menu
$header-hor-menu-default-link-font-color: $brand-main-color;
$header-hor-menu-default-link-font-color-on-hover: $brand-complementary-color;
$header-hor-menu-default-link-font-color-on-active: $brand-complementary-color;

$header-hor-menu-default-link-icon-color: $brand-main-color;
$header-hor-menu-default-link-icon-color-on-hover: $brand-complementary-color;
$header-hor-menu-default-link-icon-color-on-active: $brand-complementary-color;

$header-hor-menu-default-link-bg-color-on-hover: white;
$header-hor-menu-default-link-bg-color-on-active: white;

$header-hor-menu-default-link-border-bottom-color-on-hover: $brand-complementary-color;
$header-hor-menu-default-link-border-bottom-color-on-active: $brand-complementary-color;

// Default Mega Menu Sub Menu
$header-hor-menu-dropdown-menu-bg-color: $header-hor-menu-default-link-bg-color-on-hover;
$header-hor-menu-box-shadow: 5px 5px
rgba($header-hor-menu-dropdown-menu-bg-color, 0.2);

$header-hor-menu-dropdown-menu-link-bg-color-on-active: lighten(
                $header-hor-menu-default-link-bg-color-on-hover,
                4%
);
$header-hor-menu-dropdown-menu-link-bg-color-on-hover: lighten(
                $header-hor-menu-default-link-bg-color-on-hover,
                4%
);

$header-hor-menu-dropdown-menu-link-font-color: #ced5de;
$header-hor-menu-dropdown-menu-link-font-color-on-hover: #ced5de;
$header-hor-menu-dropdown-menu-link-font-color-on-active: #ced5de;

$header-hor-menu-dropdown-menu-link-icon-color: #6fa7d7;
$header-hor-menu-dropdown-menu-link-icon-color-on-hover: #6fa7d7;
$header-hor-menu-dropdown-menu-link-icon-color-on-active: #6fa7d7;

// Default Mega Menu
$header-hor-menu-mega-menu-header-font-color: #ced5de;

// Light Mega Menu
$header-light-hor-menu-default-link-font-color: $header-hor-menu-default-link-font-color;
$header-light-hor-menu-default-link-font-color-on-hover: $header-hor-menu-default-link-font-color-on-hover;
$header-light-hor-menu-default-link-font-color-on-active: $header-hor-menu-default-link-icon-color-on-active;

$header-light-hor-menu-default-link-icon-color: $header-hor-menu-default-link-icon-color;
$header-light-hor-menu-default-link-icon-color-on-hover: $header-hor-menu-default-link-icon-color-on-hover;
$header-light-hor-menu-default-link-icon-color-on-active: $header-hor-menu-default-link-font-color-on-active;

$header-light-hor-menu-default-link-bg-color-on-hover: $header-hor-menu-default-link-bg-color-on-hover;
$header-light-hor-menu-default-link-bg-color-on-active: $header-hor-menu-default-link-bg-color-on-active;

$header-light-hor-menu-default-link-bg-color-on-open: darken(#fafafc, 0%);
$header-light-hor-menu-default-link-font-color-on-open: #333;
$header-light-hor-menu-default-link-icon-color-on-open: #333;

// Light Mega Menu Sub Menu
$header-light-hor-menu-dropdown-menu-bg-color: $header-light-hor-menu-default-link-bg-color-on-open;
$header-light-hor-menu-box-shadow: 5px 5px
rgba($header-hor-menu-dropdown-menu-bg-color, 0.2);

$header-light-hor-menu-dropdown-menu-link-bg-color-on-active: darken(
                $header-light-hor-menu-dropdown-menu-bg-color,
                5%
);
$header-light-hor-menu-dropdown-menu-link-bg-color-on-hover: darken(
                $header-light-hor-menu-dropdown-menu-bg-color,
                5%
);

$header-light-hor-menu-dropdown-menu-link-font-color: #000;
$header-light-hor-menu-dropdown-menu-link-font-color-on-hover: #000;
$header-light-hor-menu-dropdown-menu-link-font-color-on-active: #000;

$header-light-hor-menu-dropdown-menu-link-icon-color: #6fa7d7;
$header-light-hor-menu-dropdown-menu-link-icon-color-on-hover: #6fa7d7;
$header-light-hor-menu-dropdown-menu-link-icon-color-on-active: #6fa7d7;

// Light horizontal mega menu
$header-light-hor-menu-mega-menu-header-font-color: #555;

// Mobile Mega Menu
$header-mobile-hor-menu-default-link-font-color: $brand-main-color;

$header-mobile-hor-menu-default-link-bg-color-on-active: $brand-complementary-color;
$header-mobile-hor-menu-default-link-font-color-on-active: $brand-complementary-color;

$header-mobile-hor-menu-default-link-bg-color-on-hover: $brand-complementary-color;
$header-mobile-hor-menu-default-link-font-color-on-hover: $brand-complementary-color;

// Header search
$header-search-bg-color: #38414c;
$header-search-bg-hover-color: $header-hor-menu-default-link-bg-color-on-hover;
$header-search-bg-color-on-open: $header-search-bg-hover-color;
$header-search-font-color: #616d7d;
$header-search-placeholder-font-color: darken($header-search-font-color, 1%);

// Top menu
$header-top-menu-fixed-border-box: 0px 1px 10px 0px rgba(50, 50, 50, 0.2);
$header-top-menu-separator-color: #e3e8ec;
$header-top-menu-bg-hover-color: #fff;
$header-top-menu-bg-hover-color-on-dropdown-open: #fff;
$header-top-menu-badge-bg-color: $brand-main-color;
$header-top-menu-badge-font-color: #ffffff;
$header-top-menu-icon-font-color: #ffffff;

// Top Menu Extended Dropdowns
$header-top-menu-extended-dropdown-item-bg-color-on-hover: #f8f9fa;
$header-top-menu-extended-dropdown-item-border-color: #eff2f6;
$header-top-menu-extended-dropdown-item-font-color: #222222;

$header-top-menu-extended-dropdown-header-bg-color: #f7f8fa;
$header-top-menu-extended-dropdown-header-font-color: #6f949c;

$header-top-menu-inbox-dropdown-from-font-color: #5b9bd1;

$header-top-menu-task-dropdown-progress-bg-color: #dfe2e9;

// Top Menu Notifications
$header-top-menu-notification-time-bg-color: lighten(#eee, 1%);

$header-top-menu-dropdown-dark-bg-color: darken($header-hor-menu-bg-color, 10%);
$header-top-menu-dropdown-dark-header-bg-color: darken(
                $header-top-menu-dropdown-dark-bg-color,
                3%
);
$header-top-menu-dropdown-dark-header-font-color: lighten(
                $header-top-menu-dropdown-dark-header-bg-color,
                50%
);

$header-top-menu-dropdown-dark-item-font-color: #aaafb7;
$header-top-menu-dropdown-dark-item-border-color: lighten(
                $header-top-menu-dropdown-dark-bg-color,
                6%
);
$header-top-menu-dropdown-dark-item-icon-color: #6fa7d7;
$header-top-menu-dropdown-dark-item-bg-color-on-hover: lighten(
                $header-top-menu-dropdown-dark-bg-color,
                4%
);

$header-top-menu-dropdown-dark-default-menu-divider: $header-top-menu-dropdown-dark-item-border-color;

$header-top-menu-dropdown-dark-notification-time-bg-color: darken(
                $header-top-menu-dropdown-dark-bg-color,
                5%
);

// Top Menu User bar
$header-top-menu-user-font-color: darken(white, 10%);
$header-top-menu-user-bg-color: $header-search-bg-color;
$header-top-menu-user-font-color-on-hover: white;

// Footer
$footer-pre-bg-color: lighten($header-hor-menu-bg-color, 2%);
$footer-pre-font-color: lighten($footer-pre-bg-color, 35%);
$footer-default-font-color: lighten($header-hor-menu-bg-color, 37%);
$footer-bg-color: darken($brand-main-color, 4%);
$footer-scroll-top-icon-color: lighten($header-hor-menu-bg-color, 15%);
