/***
Input icons
***/

.input-icon {
    position: relative;

    > .form-control {
        padding-left: rem(33px);

        .input-group & {
             @include border-radius($portlet-border-radius 0 0 $portlet-border-radius);
        }
    }

    > i {
        color: #ccc;
        display: block;
        position: absolute;
        margin: rem(11px) rem(2px) rem(4px) rem(10px);
        z-index: 3;
        width: $icon-size-normal;
        font-size: $icon-size-medium;
        text-align: center;

        .modal & {
            z-index: $zindex-modal + 5;
        }

        .has-success & {
            color: $brand-success;
        }

        .has-warning & {
            color: $brand-warning; 
        }

        .has-info & {
            color: $brand-info;
        }

        .has-error & {
            color: $brand-danger;
        }
    }

    &.right {
        > .form-control {
            padding-right: rem(33px);
            padding-left: rem(12px);

            .input-group & {
                @include border-radius(0 $portlet-border-radius $portlet-border-radius 0); 
            }
        }

        > i {
            right: rem(8px);
            float: right;
        } 
    }

    &.input-icon-lg {
        > i {
            margin-top: rem(16px);
        }
    }

    &.input-icon-sm {
        > i {
            margin-top: rem(8px);
            font-size: $icon-size-small;
        } 
    }
}