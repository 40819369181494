/***
UI Loading
***/

.loading-message {
    display: inline-block;
    min-width: 125px;
    margin-left: -60px;
    padding: 10px;
    margin: 0 auto;
    color: #000 !important;
    font-size: $font-size-small;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;

    &.loading-message-boxed {
        border: 1px solid #ddd;
        background-color: #eee;  

        @include border-radius($general-border-radius);

        -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);  
    }

    > span {
        line-height:20px;
        vertical-align: middle;
    }
}

.page-loading {
    @include border-radius($general-border-radius);

    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 125px;
    margin-left: -60px;
    margin-top: -30px;
    padding: 7px;
    text-align: center;
    color: #333;
    font-size: $font-size-small;
    border: 1px solid #ddd;
    background-color: #eee;  
    vertical-align: middle;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);  

    > span {
        line-height:20px;
        vertical-align: middle;
    }
}

.page-spinner-bar {
    position: fixed;
    z-index: $zindex-modal + 1;
    width: 100px;
    top: 40%;
    left: 50%;
    margin-left: -55px;
    text-align: center;

    > div {
        margin: 0 5px;
        width: 18px;
        height: 18px;
        background: #eee;

        border-radius: 100% !important;
        display: inline-block;
        
        -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
          animation: bounceDelay 1.4s infinite ease-in-out;
         
         /* Prevent first frame from flickering when animation starts */
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .bounce2 {
        -webkit-animation-delay: -0.16s;
         animation-delay: -0.16s;
    }
}

.block-spinner-bar {
    display: inline-block;
    width: 80px;
    text-align: center;  

    > div {
        margin: 0 2px;
        width: 15px;
        height: 15px;
        background: #eee;
        border-radius: 100% !important;
        display: inline-block;

        -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
          animation: bounceDelay 1.4s infinite ease-in-out;
         
         /* Prevent first frame from flickering when animation starts */
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .bounce1 {
        -webkit-animation-delay: -0.32s;  
        animation-delay: -0.32s;
    }

    .bounce2 {
        -webkit-animation-delay: -0.16s;
         animation-delay: -0.16s;
    }
}
.spinner-relative {
    width: 70px;
    text-align: center;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
}
.spinner {
    width: 70px;
    text-align: center;

    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spinner > div, .spinner-relative > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1, .spinner-relative .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2, .spinner-relative .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}
