.recharts-responsive-container {
  .recharts-cartesian-axis-line {
    stroke: none;
  }
  &.light {
    .recharts-cartesian-axis-tick {
      text {
        fill: #ffffff;
        font-size: $font-size-small;
        font-weight: bold;
      }
    }
    g.recharts-cartesian-grid,
    g.recharts-cartesian-grid-vertical {
      line {
        stroke: darken($brand-main-color, 10%);
      }
    }
    line.recharts-cartesian-axis-tick-line {
      stroke: darken($brand-main-color, 10%);
    }
    .recharts-default-tooltip {
      border-radius: 3px;
      border: none !important;
      background-color: darken($brand-main-color, 20%) !important;
      .recharts-tooltip-label {
        color: #ffffff;
      }
    }
  }
}

.recharts-tooltip-wrapper {
  z-index: 10;
  .recharts-default-tooltip {
    margin: 0;
    padding: 10px;
    .recharts-tooltip-label {
      margin: 0;
    }
    .recharts-tooltip-item-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
      .recharts-tooltip-item {
        line-height: 24px;
        margin: 0;
        padding: 0;
        .recharts-tooltip-item-name {
        }
        .recharts-tooltip-item-separator {
        }
        .recharts-tooltip-item-value {
        }
        .recharts-tooltip-item-unit {
        }
      }
    }
  }
}

.recharts-legend-wrapper {
  width: 100% !important;
  > div {
    margin-bottom: -23px;
    .btn {
      margin-top: 2px;
      margin-right: 5px;
      margin-left: 0;
    }
  }
}
