.upload-component {
  .dropzone {
    text-align: center;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    min-height: 60px;
    border: 2px dashed #333;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      background-color: lighten($brand-primary, 35%);
      border: 2px dashed darken($brand-primary, 10%);
    }
    .image-upload-preview {
      position: relative;
      display: block;
      z-index: 0;
      padding: 5px;
      margin: 0 auto;
      &.loading img {
        opacity: 0.75;
      }
      a {
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        position: absolute;
        display: inline-block;
      }
    }
  }
}

ul {
  > li {
    .upload-component,
    .alert {
      margin-bottom: 0;
    }
  }
}
