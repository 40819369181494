.messages-form {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 15px 5px;
  background: $brand-main-color;
  width: 100%;
  display: block;
  z-index: 6;
  box-shadow: 0 -3px 1px 0 rgba(0, 0, 0, 0.2);
  .form-group {
    margin-bottom: 0;
  }
  .help-block {
    color: #ff6f75;
  }
  .btn-success {
    font-size: 1rem;
  }
}
.nano {
  position: fixed;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 60px;
  .has-scrollbar {
    max-height: 100%;
    overflow-x: hidden;
    padding: 0 10px;
    position: relative;
  }
}
@media (max-width: $screen-sm-max) {
  .nano {
    top: 70px;
  }
}
.mar-btm {
  margin-bottom: 15px;
}
.media-block {
  .media-left {
    display: block;
    float: left;
  }
  .media-body {
    display: block;
    overflow: hidden;
    width: auto;
  }
  .media-right {
    float: right;
  }
}
.img-sm {
  width: 46px;
  height: 46px;
}
.pad-hor {
  padding: 0 15px;
}
.speech {
  position: relative;
  background: $brand-main-color;
  color: white;
  display: inline-block;
  border-radius: 12px;
  padding: 12px 20px;
  margin-right: 20px;
  margin-left: 0;
  min-width: 33%;
  border-bottom: 3px darken($brand-main-color, 10%) solid;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid $brand-main-color;
    margin: 15px 0 0 -6px;
  }
  .media-heading {
    font-size: 1.2em;
    color: white;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-weight: 300;
  }
  .readMessageLabel {
    font-size: rem(12px);
  }
}
.speech-right {
  text-align: right;
  > .speech {
    background: lighten($brand-complementary-color, 50%);
    color: darken($brand-complementary-color, 20%);
    text-align: right;
    margin-right: 0;
    margin-left: rem(20px);
    border-bottom: 2px darken($brand-complementary-color, 10%) solid;
    &:before {
      left: auto;
      right: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid lighten($brand-complementary-color, 50%);
      border-right: 0;
      margin: rem(15px) rem(-6px) 0 0;
    }
    .media-heading {
      color: darken($brand-complementary-color, 20%);
    }
  }
  .readMessageLabel {
    display: inline-block;
    float: right;
    margin-left: rem(20px);
    font-size: rem(12px);
  }
}
.speech-time {
  margin-top: rem(20px);
  margin-bottom: 0;
  font-weight: 300;
}
