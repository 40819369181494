//colors
$color_dark: #333333;
$concrete: #f2f2f2;
$color_celeste_approx: #cccccc;
$white: rgb(255, 255, 255);

%extend_1 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%extend_2 {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}

%extend_3 {
  box-sizing: content-box;
  content: '';
  color: $color_dark;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $color_dark;
  text-align: center;
  transition: all 0.4s ease;
}

%extend_4 {
  box-sizing: content-box;
  content: '';
  background-color: $color_dark;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

%extend_5 {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: $white;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

%extend_6 {
  content: '';
  transition: transform 200ms ease-out;
  transform: rotate(-45deg) scale(1);
}

.tmm-checkbox {
  input[type='checkbox'] {
    @extend %extend_1;
    + label {
      @extend %extend_2;
      &:before {
        @extend %extend_3;
      }
      &:after {
        @extend %extend_4;
        @extend %extend_5;
      }
    }
    &:disabled {
      + label:before {
        border-color: $color_celeste_approx;
      }
      &:hover + label:before {
        background-color: inherit;
      }
      &:checked + label:before {
        background-color: $color_celeste_approx;
      }
    }
    &:hover + label:before {
      border-color: $color_dark;
      background-color: $concrete;
    }
    &:active + label:before {
      transition-duration: 0s;
    }
    &:checked + label {
      &:after {
        @extend %extend_6;
      }
      &:before {
        animation: borderscale 200ms ease-in;
        background: $color_dark;
      }
    }
  }
}
.tmm-radio {
  input[type='radio'] {
    @extend %extend_1;
    + label {
      @extend %extend_2;
      &:before {
        @extend %extend_3;
        border-radius: 50%;
      }
      &:after {
        @extend %extend_4;
        border-radius: 50%;
      }
    }
    &:disabled {
      + label:before {
        border-color: $color_celeste_approx;
      }
      &:hover + label:before {
        background-color: inherit;
      }
      &:checked + label:before {
        background-color: $color_celeste_approx;
      }
    }
    &:hover + label:before {
      border-color: $color_dark;
      background-color: $concrete;
    }
    &:active + label:before {
      transition-duration: 0s;
    }
    &:checked + label {
      &:before {
        animation: borderscale 300ms ease-in;
        background-color: $white;
      }
      &:after {
        transform: scale(1);
      }
    }
  }
}
@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $color_dark;
  }
}

%extend_1_light {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%extend_2_light {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}

%extend_3_light {
  box-sizing: content-box;
  content: '';
  color: $white;
  position: absolute;
  top: 50%;
  left: 0;
  width: 22px;
  height: 22px;
  margin-top: -9px;
  background-color: rgba($white, 0.25);
  border: 2px solid rgba($white, 0.5);
  text-align: center;
  transition: all 0.4s ease;
}

%extend_4_light {
  box-sizing: content-box;
  content: '';
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 8px;
  width: 10px;
  height: 10px;
  margin-top: -2px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

%extend_5_light {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: $color_dark;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

%extend_6_light {
  content: '';
  transition: transform 200ms ease-out;
  transform: rotate(-45deg) scale(1);
}

.tmm-checkbox-light {
  input[type='checkbox'] {
    @extend %extend_1_light;
    + label {
      @extend %extend_2_light;
      &:before {
        @extend %extend_3_light;
      }
      &:after {
        @extend %extend_4_light;
        @extend %extend_5_light;
      }
    }
    &:disabled {
      + label:before {
        border-color: $color_celeste_approx;
      }
      &:hover + label:before {
        background-color: inherit;
      }
      &:checked + label:before {
        background-color: $color_celeste_approx;
      }
    }
    &:hover + label:before {
      border-color: $white;
      background-color: $concrete;
    }
    &:active + label:before {
      transition-duration: 0s;
    }
    &:checked + label {
      &:after {
        @extend %extend_6_light;
      }
      &:before {
        animation: borderscale 200ms ease-in;
        background: $white;
      }
    }
  }
}
.tmm-radio-light {
  margin-bottom: 5px;
  input[type='radio'] {
    @extend %extend_1_light;
    + label {
      @extend %extend_2_light;
      &:before {
        @extend %extend_3_light;
        border-radius: 50%;
      }
      &:after {
        @extend %extend_4_light;
        border-radius: 50%;
      }
    }
    &:disabled {
      + label:before {
        border-color: $color_celeste_approx;
      }
      &:hover + label:before {
        background-color: inherit;
      }
      &:checked + label:before {
        background-color: $color_celeste_approx;
      }
    }
    &:hover + label:before {
      background-color: $brand-success;
    }
    &:active + label:before {
      transition-duration: 0s;
    }
    &:checked + label {
      &:before {
        animation: borderscale 300ms ease-in;
        background-color: $brand-success;
        border-width: 0;
        width: 26px;
        height: 26px;
        box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.5);
      }
      &:after {
        transform: scale(1);
      }
    }
  }
}
@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $white;
  }
}

.tmm-checkbox-button,
.tmm-radio-button {
  padding: 0 5px;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: center;
  img {
    cursor: pointer;
  }
  input[type='checkbox'],
  input[type='radio'] {
    opacity: 0;
    margin: 0;
    pointer-events: none;
    padding: 0;
    position: absolute;
    ~ label {
      border-width: 0;
      padding: 15px;
      font-size: $font-size-large;
      outline: none !important;
      background-image: none !important;
      filter: none;
      text-align: center;
      width: 100%;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.2);
      transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;
    }
  }
  input[type='checkbox'] ~ label:hover,
  input[type='checkbox'] ~ label:focus,
  input[type='radio'] ~ label:hover,
  input[type='radio'] ~ label:focus {
    background-color: rgba($brand-success, 0.5);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  }
  input[type='checkbox']:checked ~ label,
  input[type='checkbox']:checked ~ label:active,
  input[type='checkbox'] ~ label:active,
  input[type='radio']:checked ~ label,
  input[type='radio']:checked ~ label:active,
  input[type='radio'] ~ label:active {
    background-color: $brand-success;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
  }
}

.custom-activity-checkbox-button {
  list-style-type: none;
  margin: 10px 0 10px 10px;
  border-radius: 4px;
  background: transparent !important;
  padding: 0 !important;
  position: relative;
  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    pointer-events: none;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    ~ label {
      border-width: 0;
      padding: 10px 10px 10px 30px;
      outline: none !important;
      background-image: none !important;
      filter: none;
      width: 100%;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 1);
      transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;
      &:before {
        box-sizing: content-box;
        content: '';
        color: $color_dark;
        position: absolute;
        top: 50%;
        left: 8px;
        width: 14px;
        height: 14px;
        margin-top: -9px;
        border: 2px solid $color_dark;
        text-align: center;
        transition: all 0.4s ease;
        background: $color_dark;
      }
      &:after {
        box-sizing: content-box;
        content: '';
        position: absolute;
        transform-origin: 50%;
        background-color: transparent;
        top: 50%;
        left: 12px;
        width: 8px;
        height: 3px;
        margin-top: -4px;
        border-style: solid;
        border-color: $white;
        border-width: 0 0 3px 3px;
        border-image: none;
        transition: transform 200ms ease-out;
        transform: rotate(-45deg) scale(1);
      }
    }
  }
  input[type='checkbox'] ~ label:hover,
  input[type='checkbox'] ~ label:focus {
    background-color: rgba(255, 255, 255, 0.5);
  }
  input[type='checkbox']:checked ~ label,
  input[type='checkbox']:checked ~ label:active,
  input[type='checkbox'] ~ label:active {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #777777;
    &:before {
      animation: borderscale 200ms ease-in;
      background: transparent;
    }
    &:after {
      content: '';
      transition: transform 200ms ease-out;
      transform: rotate(-45deg) scale(0);
    }
  }
  input[type='checkbox']:disabled ~ label:before,
  input[type='checkbox']:disabled ~ label:after {
    opacity: 0.3;
  }
}
