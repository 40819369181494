/***
Customized Bootstrap Labels
***/

.label {
    text-shadow: none !important;
    font-size: $font-size-small;
    font-weight: 300;  
    padding: 3px 6px 3px 6px;
    margin-right: 1px;
    color: #fff;
    font-family: $font-family-primary;   

    &.label-sm {   
        font-size: $font-size-small;
        padding: 0px 4px 1px 4px;  
    }

    h1 &,
    h2 &,
    h3 &,
    h4 &,
    h5 &,
    h6 & {
        font-size:75%;
    }
}

/* Labels variants */

.label-default  {
  @include label-variant($brand-default, $brand-default-color);
}

.label-primary  {
  @include label-variant($brand-primary, $brand-primary-color)
}

.label-info  {
  @include label-variant($brand-info, $brand-info-color);
}

.label-success  {
  @include label-variant($brand-success, $brand-success-color);
}

.label-danger  {
  @include label-variant($brand-danger, $brand-danger-color);
}

.label-warning  {
  @include label-variant($brand-warning, $brand-warning-color);
}

/***
Iconic labels
***/

.label.label-icon {
    padding: 4px 0px 4px 4px;
    margin-right: 2px;
    text-align: center !important;

    > i {
        font-size: $font-size-small;
        text-align: center !important;
    }

    .ie8 &,
    .ie9 & {
        padding: 3px 0px 3px 3px;
    }
}

/***
Text states
***/

.text-default {
    color: $brand-default;
}

.text-primary {
    color: $brand-primary;
}

.text-success {
    color: $brand-success;
}

.text-info {
    color: $brand-info;
}

.text-warning {
    color: $brand-warning;
}

.text-danger {
    color: $brand-danger;
}

@if $theme-type == "material-design" {
    .label {
        @extend .md-shadow-z-1;  
        text-transform: uppercase;
        padding: 2px 6px 4px 6px;
        font-size: $font-size-x-small;
        font-weight: 600;  

        &.label-sm {  
            font-size: $font-size-x-small;
            font-weight: 600;
            padding: 3px 6px 3px 6px;
        }
    }

    .label.label-icon {
        padding: 4px 0px 4px 4px !important;
        font-size: $font-size-small !important;
        
        .ie8 &,
        .ie9 & {
            padding: 3px 0 3px 3px !important;
        }
    }
}
