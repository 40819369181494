/***
Customized Progress Bars
***/

.progress {  
  border: 0; 
  background: rgba(220,220,220,0.5);
  filter: none;
  box-shadow: none;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;   

  &.progress-sm {
    height: 12px;
  }
}

.progress > .progress-bar-default {
    @include progress-bar-variant($brand-default);
}

.progress > .progress-bar-success {
    @include progress-bar-variant($brand-success);
}

.progress > .progress-bar-info {
    @include progress-bar-variant($brand-info);
}

.progress > .progress-bar-danger {
    @include progress-bar-variant($brand-danger);
}

.progress > .progress-bar-warning {
    @include progress-bar-variant($brand-warning);
}

@if $theme-type == "material-design" {
    .progress {
        height: 8px;
        border-radius: 0 !important;

        &.progress-sm {
            height: 6px;
        }
    }
}
