@media (min-width: 768px) and(max-width: 1200px) {
  .form-wizard .step .desc {
    margin-top: 10px;
    display: block;
  }
}
@media (max-width: 768px) {
  .form-wizard {
    .steps {
      display: none;
    }
  }
}
.form-wizard {
  .form-actions {
    border-radius: inherit;
    border-color: rgba(0, 0, 0, 0.25);
    padding: 20px;
    z-index: 6;
    &.top {
      background-color: inherit;
      border-bottom: none;
    }
  }
  .survey-form-actions {
    background: linear-gradient(
      to right,
      lighten($brand-main-color, 10),
      $brand-main-color
    ) !important;
  }
  .form-body {
    padding: 20px;
    min-height: 350px;
  }
  .progress {
    margin: 0 20px;
    border-radius: 10px;
    .progress-bar {
      background: linear-gradient(
        to right,
        lighten($brand-main-color, 25),
        $brand-complementary-color
      ) !important;
      border-radius: 10px;
    }
  }
  .steps {
    display: none;
    > li {
      > span.step {
        width: 100%;
        -webkit-filter: none;
        filter: none;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        display: inline-block;
        text-align: center;
        &:hover {
          background: none;
        }
        >  {
          .number {
            background-color: rgba(220, 220, 220, 0.5);
            display: inline-block;
            text-align: center !important;
            font-size: $font-size-medium;
            font-weight: 300;
            padding: 4px;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            border-radius: 50% !important;
          }
          .desc {
            display: inline-block;
            font-size: $font-size-medium;
            font-weight: 300;
            > i {
              display: none;
            }
          }
        }
      }
      &.active > span.step {
        .number {
          background-color: $brand-success;
          color: white;
        }
        .desc {
          color: #333;
        }
      }
      &.done > span.step {
        .number {
          background-color: white;
          color: $brand-primary;
        }
        .desc {
          color: #333;
          i {
            font-size: $font-size-small;
            font-weight: normal;
            color: #999;
            display: inline-block;
          }
        }
      }
    }
  }
}
