/* Pre-footer */
.page-prefooter {
  padding: rem(30px) 0;
  clear: both;

  h2 {
    font-weight: 700;
    font-size: $font-size-medium;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 rem(12px);
  }

  .subscribe-form {
    padding-top: rem(5px);

    .form-control {
      font-size: $font-size-small;
      padding: 0 rem(14px);
      height: rem(36px);
    }

    .btn {
      height: rem(34px);
      text-transform: uppercase;
      padding: rem(7px) rem(16px);
    }
  }

  .social-icons {
    padding-top: rem(9px);

    li {
      @include opacity(0.35);

      &:hover {
        @include opacity(1);
      }
    }
  }

  p,
  address {
    margin: 0;
  }
}

/* Footer */
.page-footer {
  font-size: $font-size-small;
  font-weight: 300;
  padding: rem(17px) 0 rem(10px);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 5;
  background: $brand-main-color;
  color: $brand-main-font-color;
  &.mobile-footer {
    position: relative;
    margin-bottom: -30px;
    padding: rem(5px) 0;
    p,
    strong {
      margin: 0;
      padding: 0;
      line-height: rem(24px);
    }
  }
}

@media (max-width: $screen-sm-max) {
  /* 991px */
  .page-prefooter {
    padding-bottom: rem(10px);

    .footer-block {
      margin-bottom: rem(20px);
    }

    @include reset-container();
  }

  .page-footer {
    @include reset-container();
  }
}

/* Scroll Top */

.scroll-to-top {
  padding: rem(2px);
  text-align: center;
  position: fixed;
  z-index: $zindex-go-to-top;
  bottom: rem(5px);
  display: none;
  right: rem(20px);

  > i {
    display: inline-block;
    font-size: rem(32px);
    @include opacity(0.7);
  }

  &:hover {
    cursor: pointer;

    > i {
      @include opacity(1);
    }
  }
}

@media (max-width: $screen-sm-max) {
  /* 991px */
  .scroll-to-top {
    right: rem(10px);

    > i {
      font-size: $icon-size-x-large;
    }
  }
}

@media only screen and (max-device-width: $screen-sm-max) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-footer {
    p {
      text-align: center;
    }
  }
}
