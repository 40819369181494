@import 'variables'; // global variables.
@import '../../components/mixins'; // global mixings.

/***
Login page
***/

/* bg color */
$bg-color: #000000;
$font-light: #ffffff;

html, body {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.login {
  color: white;
  min-height: 100vh;
  a {
    color: white !important;
  }
  .header {
    width: 100%;
    height: 100px;
    text-align: center;
    background: white;
    .logo {
      margin: 0 auto;
      padding: 20px 0;
      text-align: center;
      img {
        height: 60px;
        max-width: 80%;
        width: auto;
      }
    }
  }
  .copyright {
    text-align: center;
    padding: 10px 0 30px 0;
    font-size: $font-size-small;
    clear: both;
  }
  .content {
    width: rem(500px);
    margin: rem(40px) auto rem(40px) auto;

    h4 {
      color: #555;
    }
    .hint {
      color: lighten($bg-color, 25%);
      padding: 0;
      font-size: $font-size-normal;
      margin: 15px 0 7px 0;
    }
    .forget-form, .login-form {
      padding: 0px;
      margin: 0px;
      .input-icon > i {
        color: white;
      }
      .form-title {
        margin-bottom: rem(20px);
        font-size: $font-size-medium;
        font-weight: 400 !important;
      }
      .form-subtitle {
        font-size: $font-size-medium;
        font-weight: 300 !important;
        padding-left: rem(10px);
      }
      .form-actions {
        clear: both;
        border: 0px;
        padding: 0px;
        margin: rem(20px) 0;
        font-weight: bold;
        .forget-password-block {
          padding-bottom: 7px;
        }
      }
      .has-error{
        .control-label {
          font-weight: bold;
        }
        .help-block {
          background-color: #f2dede;
          border: 1px solid #ebccd1;
          color: #a94442;
          padding: rem(15px);
          margin-bottom: rem(20px);
          display: block;
        }
      }

      .form-control {
        border: none;
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid $brand-main-color;
        height: rem(43px);
        color: white;
        font-size: $font-size-normal;
        &:focus, &:active {
          border-bottom: 1px solid lighten($bg-color, 100%);
        }
        @include placeholder($font-light);
        label, legend {
          color: $font-light;
          font-weight: bold;
        }
        select.form-control {
          padding-left: rem(9px);
          padding-right: rem(9px);
        }
      }
    }
  }
}

.login-options {
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 50px;
  border-top: 1px solid #69a0c4;
  border-bottom: 1px solid #69a0c4;
}

.login-options h4 {
  margin-top: 8px;
  font-weight: 600;
  font-size: $font-size-medium;
  color: lighten($bg-color, 25%) !important;
}

.login .content button[type=submit] {
  padding: rem(10px);
  border-bottom: 2px solid darken($bg-color, 10%);
}

.login .content .check {
  color: lighten($bg-color, 30%) !important;
}

.login .content .create-account {
  text-align: center;
  margin-top: 20px;
}

.login .content .create-account p a {
  font-weight: 300;
  font-size: $font-size-medium;
  color: #ffffff;
}

@media (max-width: 480px) {
  /***
  Login page
  ***/

  .login .logo {
    padding: 30px 0 0;
  }

  .login .content {
    width: 280px;
  }

  .login .content h3 {
    font-size: $font-size-large;
  }

  .login .checkbox {
    font-size: $font-size-small;
  }
}
