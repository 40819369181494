.side-menu-container {
  margin-bottom: 10px;
}
.side-menu {
  position: relative;
  box-shadow: $block-shadow;
  z-index: 1;
  ul {
    position: relative;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    > li {
      float: left;
      width: auto;
      display: block;
      margin-right: 2px;
      &:first-child > a {
        border-radius: 0 0 0 4px;
      }
      &:last-child > a {
        border-radius: 0 0 4px 0;
      }
      &:only-child > a {
        border-radius: 0 0 4px 4px;
      }
      a {
        position: relative;
        display: block;
        height: 50px;
        line-height: 50px;
        font-size: 1.4rem;
        padding: 0 20px 0 10px;
        text-decoration: none;
        background: lighten($brand-main-color, 35%);
        &:hover, &.active {
          background: darken($brand-complementary-color, 5%);
          text-decoration: none;
          > span {
            color: white;
            text-decoration: none;
          }
          > i {
            color: white;
          }
        }
        &.active {
          background: $brand-complementary-color;
          color: white;
        }
        > i {
          content: '';
          position: absolute;
          height: 16px;
          width: 16px;
          font-size: $icon-size-medium;
          left: 22px;
          top: 16px;
          color: $brand-main-color;
        }
        &::before {
          border-top: 6px solid darken($brand-complementary-color, 5%);
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          left: 50%;
          bottom: -5px;
          transform: translateY(-5px) scaleY(0) translateX(-50%);
        }
        > span {
          color: $brand-main-color;
          background: none;
          margin-left: 42px;
          position: relative;
        }
      }
    }
  }
  & ul {
    a {
      &::after {
        /* navigation item icons */
        transform: translateX(50%) scale(1);
        animation: scaleIn 0.15s backwards;
        transition: opacity 0.2s;
      }
      &:hover, &.active {
        &::after {
          opacity: 1;
        }
        &::before {
          transform: translateY(0px) scaleY(1) translateX(-50%);
          transition: transform 0.15s 0.3s;
        }
        >  {
          i {
            color: #fafafa;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  /* 991px */
  .side-menu {
    ul {
      > li {
        width: 60px;
        a {
          position: relative;
          display: block;
          height: 50px;
          line-height: 50px;
          font-size: 1.4rem;
          > span {
            background: darken($brand-complementary-color, 5%);
            margin-left: -12px;
            border-radius: 0 4px 4px 0;
            width: 60px;
            height: 50px;
            overflow: hidden;
            font-weight: bold;
            display: block;
            opacity: 0;
            padding: 0 10px;
            transform: translateX(0);
            position: absolute;
            z-index: 10;
          }
          &:hover {
            span {
              color: white;
              opacity: 1;
              min-width: 200px;
              transform: translateX(60px);
              animation: slideIn 0.15s backwards;
              transition: transform 0.2s;
            }
          }
          &.active{
            span {
              background: $brand-complementary-color;
            }
          }
        }
      }
    }
  }
}
