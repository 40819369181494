h1, h2, h3, h4, h5, h5 {
  color: #222222;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
}

.heading {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 18px;
  color: #222222;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.heading::before {
  content: '';
  position: absolute;
  width: 46px;
  height: 12px;
  border: 2px solid #222;
  border-bottom: 0px;
  border-color: rgba(34, 34, 34, 0.1);
  left: 50%;
  margin-left: -23px;
  top: -18px;
}
.heading::after {
  content: '';
  position: absolute;
  width: 46px;
  height: 12px;
  border: 2px solid #222;
  border-top: 0px;
  border-color: rgba(34, 34, 34, 0.1);
  left: 50%;
  margin-left: -23px;
  bottom: -18px;
}

// customize react table

.rt-resizable-header {
  background: #f1f1f1;
  &.-cursor-pointer {
    background: white;
  }
}

.ReactTable {
  .rt-noData {
    background: #959dae;
    font-weight: bold;
    z-index: 0;
    color: white;
    border-radius: 3px;
    position: relative;
    transform: translate(0, 0);
    left: 0;
    top: 0;
    text-align: center;
    padding: 10px;
    margin: 3px;
  }
  .rt-td {
    min-height: 34px;
  }
  .rt-thead {
    .rt-th.-sort-asc,
    .rt-td.-sort-asc {
      background-color: lighten($brand-success, 55%);
      box-shadow: inset 0 3px 0 0 $brand-success;
    }
    .rt-th.-sort-desc,
    .rt-td.-sort-desc {
      background-color: lighten($brand-success, 55%);
      box-shadow: inset 0 -3px 0 0 $brand-success;
    }
  }
}

// customize react widgets multiselect
.rw-multiselect-taglist  {
  display:inline;
}

.rw-multiselect-taglist > li .rw-btn {
  margin-left: 5px;
  font-size: 1.1em;
  color: red;
}

.rw-multiselect .rw-widget-picker > div {
  vertical-align: top;
}

// fix form-control on multi browsers

.form-control {
  display: inline-block;
}

// display value for numericFormula
.numeric-formula-value {
  position: relative;
  display: block;
  background: $brand-complementary-color;
  border-radius: 3px;
  line-height: 80px;
  font-size: 40px;
  padding: 10px 20px;
  margin: auto;
  max-width: 400px;
  text-align: center;
}
@media (max-width: $screen-sm-max) {
  .numeric-formula-value {
    max-width: 150px;
    font-size: $font-size-large;
    padding: 5px 10px;
    line-height: 60px;
  }
  .rc-slider-mark-text h2 {
    font-size: $font-size-large;
  }
}

.slider-value,
.slider-value-limit {
  position: relative;
  display: block;
  background: $brand-complementary-color;
  border-radius: 3px;
  line-height: 40px;
  font-size: 30px;
  padding: 10px 20px;
  margin: auto;
  max-width: 100%;
  text-align: center;
}
.slider-value-limit {
  background: none;
  border-radius: 0;
  line-height: 30px;
  font-size: $font-size-large;
  margin-top: -20px;
}

// improve numeric input field
.form-control.numeric {
  line-height: 50px;
  font-size: $font-size-large;
  height: 62px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.form-control.text {
  line-height: 30px;
  font-size: $font-size-large;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.form-group.form-widget .react-datepicker__input-container > input {
  line-height: 50px;
  font-size: $font-size-large;
  height: 62px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 250px;
}

// override numeric slider component style
.numeric-slider {
  overflow: hidden;
  width: 100%;
  padding: 0 10%;
  min-height: 100px;
}
.rc-slider-track {
  background-color: $brand-complementary-color !important;
}
.rc-slider-handle {
  border: solid 2px $brand-complementary-color !important;
  background: lighten($brand-complementary-color, 40%) !important;
}

.rc-slider-dot-active {
  border: 2px solid $brand-complementary-color !important;
  background: #10817b !important;
}

// custom activities tabs
#activitiesTabs .nav-tabs {
  border-radius: 20px;
  border: none;
  background: #eee;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
  > li {
    width: 50%;
    text-align: center;
    background-color: transparent;
    text-transform: uppercase;
    &.active {
      a,
      a:hover {
        color: white;
        margin-right: 0;
        border-radius: 20px;
        z-index: 1;
        background: linear-gradient(
          to right,
          $brand-complementary-color 0%,
          $brand-main-color 100%
        );
        .label-primary {
          background-color: white;
          color: $brand-primary;
        }
      }
    }
    a,
    a:hover {
      color: #333;
      border-radius: 20px;
      border-bottom: none;
    }
  }
}

// Custom calendar event
.customEvent {
  &.phe {
    background-color: $brand-info !important;
    border-left: 1px solid darken($brand-info, 5%) !important;
  }
  &.available {
    background-color: $brand-primary !important;
    border-left: 1px solid darken($brand-primary, 5%) !important;
  }
  &.unavailable {
    background-color: darken($brand-default, 20) !important;
    border-left: 1px solid darken($brand-default, 25%) !important;
  }
  &.answered {
    background-color: $brand-success !important;
    border-left: 1px solid darken($brand-success, 5%) !important;
  }
  &.unanswered {
    background-color: $brand-danger !important;
    border-left: 1px solid darken($brand-danger, 5%) !important;
  }
  &:hover {
    z-index: 10000 !important;
    opacity: 1 !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .eventStartingTime {
    font-size: 0.75em;
  }
}

table td.rbc-agenda-date-cell {
  background: white;
  color: black;
}

table {
  tr.customEvent {
    font-size: 1.2em;

    &:hover {
      box-shadow: none;
    }
    > td {
      border-bottom: 3px solid white;
    }
  }
}

.rbc-agenda-content .eventStartingTime {
  display: none;
}
.rbc-event-content .answer-button {
  display: none;
}
.rbc-agenda-content .answer-button {
  display: inline-block;
  border-radius: 14px !important;
}
.rbc-event-label {
  display: none;
}

.rbc-row-content {
  z-index: unset;
}

.rbc-agenda-view {
  flex: 0;
}

// customize react datepicker
.react-datepicker {
  font-size: 1rem;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $brand-success;
  &:hover {
    background-color: darken($brand-success, 5%);
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1rem;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.5rem solid transparent;
}

//Single Datepicker
.SingleDatePicker_picker {
  z-index: 6 !important;
}

// pdf container
.pdf-container {
  background: rgba(230, 230, 230, 0.5) !important;
  padding: 20px 10px 1px !important;
  text-align: center;
  border-radius: 3px;
}

.close-icon {
  display: block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-width: 5px;
  border-style: solid;
  border-color: #958b8a;
  border-radius: 100%;
  background: linear-gradient(
      -45deg,
      transparent 0%,
      transparent 40%,
      white 40%,
      white 60%,
      transparent 60%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 40%,
      white 40%,
      white 60%,
      transparent 60%,
      transparent 100%
    );
  background-color: #958b8a;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 5px 2px 2px rgba(0, 0, 0, 0.25);
  }
}

// custom survey form-actions
.survey-form-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 !important;
  z-index: 1;
  &.mobile-survey-footer {
    position: relative;
    z-index: 0 !important;
  }
}

// Collapsible module styling
.Collapsible__trigger {
  font-weight: 400;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  &:before {
    font-family: 'Glyphicons Halflings';
    content: '\e113';
    position: absolute;
    left: 0;
    margin: 10px 0;
    display: block;
    transition: transform 300ms;
    transform: rotateZ(90deg);
  }

  &.is-open {
    &:before {
      transform: rotateZ(180deg);
    }
  }
  h4 {
    margin: 0 0 0 20px;
    padding: 10px 0;
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

// pdf container
.pdf-container {
  background: #eee;
  padding: 10px;
  text-align: center;
  .ReactPDF__Page__canvas {
    margin: 0 auto;
    max-width: 100%;
    height: auto !important;
  }
}

// react flag select
.flag-select {
  .arrow-down {
    color: #e6e6e6 !important;
  }

  img {
    vertical-align: inherit;
  }
}

// redux toastr
.redux-toastr {
  .toastr {
    .toastr-status {
      &.success {
        background-color: $toastr-successBgColor;
      }

      &.warning {
        background-color: $toastr-warningBgColor;
      }

      &.info {
        background-color: $toastr-infoBgColor;
      }

      &.error {
        background-color: $toastr-errorBgColor;
      }
    }

    &.rrt-info {
      color: $toastr-infoColor;
      background-color: $toastr-infoBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-infoBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-infoColor;
        }
      }
    }

    &.rrt-success {
      color: $toastr-successColor;
      background-color: $toastr-successBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-successBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-successColor;
        }
      }
    }

    &.rrt-warning {
      color: $toastr-warningColor;
      background-color: $toastr-warningBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-warningBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-warningColor;
        }
      }
    }

    &.rrt-error {
      color: $toastr-errorColor;
      background-color: $toastr-errorBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-errorBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-errorColor;
        }
      }
    }
  }
}

/***
Page Loading
***/

.slide-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 0;
  &.slide-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
}

.slide-enter {
  opacity: 0;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  &.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
}

.slide-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  &.slide-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transition: all 0.2s linear;
  }
}

// Customize cards
.paper {
  // portlet body is not into a portlet element but into a card
  .portlet-body {
    clear:both;
  }
}
