#digitList {
  margin: 0;
  text-align: center;
  padding: 0;
  max-height: 270px;
  .digitContainer {
    width: 90px;
    height: 270px;
    display: inline-block;
    position: relative;
    > span {
      font-size: 2em;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #eeeeee;
      line-height: 78px;
      width: 78px;
      height: 78px;
      display: block;
      position: relative;
      padding: 0;
      margin: 5px;
    }
    > button {
      text-align: center;
      border-radius: 50%;
      line-height: 60px;
      width: 60px;
      height: 60px;
      display: block;
      position: relative;
      padding: 0;
      margin: 15px;
      i {
        font-size: 1.5rem !important;
        line-height: 60px !important;
        margin: 0 !important;
        padding: 0;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  /* 991px */
  #digitList {
    margin: 0;
    text-align: center;
    padding: 0;
    .digitContainer {
      width: 60px;
      height: 180px;
      display: inline-block;
      position: relative;
      overflow: hidden;
      > span {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        border-radius: 3px;
        border: 1px solid #eeeeee;
        line-height: 48px;
        width: 48px;
        height: 48px;
        display: block;
        position: relative;
        padding: 0;
        margin: 5px;
      }
      > button {
        text-align: center;
        border-radius: 50%;
        line-height: 50px;
        width: 50px;
        height: 50px;
        display: block;
        position: relative;
        padding: 0;
        margin: 5px;
        i {
          font-size: 1.5em !important;
          line-height: 50px !important;
          margin: 0 !important;
          padding: 0;
        }
      }
    }
  }
}
