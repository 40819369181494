.profile-content {
  overflow: hidden;
}
.profile-sidebar-portlet {
  padding: 30px 0 0!important;
}
.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}
.profile-usertitle-name {
  font-size: $font-size-large;
  font-weight: 600;
  margin-bottom: 7px;
}
.profile-usertitle-active {
  color: $brand-primary;
  text-transform: uppercase;
  font-size: $font-size-small;
  font-weight: 800;
  margin-bottom: 7px;
}
.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
  .btn {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: 600;
    padding: 6px 15px;
  }
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  border-radius: 50%!important;
  max-width: 120px;
}
