.tree-list {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  border-left: 1px dotted #aaa;
  &.editor {
    > li {
      margin-left: 25px;
      &::before {
        width: 25px;
        z-index: 0;
      }
      .btn-group > button {
        position: relative;
        z-index: 1;
        box-shadow: $block-shadow;
      }
      &.conditional {
        padding-left: 25px;
        padding-top: 55px;
        background-color: rgba($brand-main-color, 0.15) !important;
        position: relative;
        .condition {
          background-color: $brand-main-color;
          color: white;
          position: relative;
          display: block;
          min-height: 50px;
          margin: -55px -10px 20px -25px;
          line-height: 50px;
          padding-right: 10px;
          border-radius: 0 4px 0 0;
          .ribbon {
            z-index: 2;
            float: left;
            padding: 0 10px;
            margin: 0 15px 0 0;
            clear: left;
            position: relative;
            background-color: $brand-info;
            color: white;
            &::after {
              content: '';
              border-top: 25px solid transparent;
              border-bottom: 25px solid transparent;
              border-left: 10px solid $brand-info;
              position: absolute;
              right: -10px;
              top: 0;
            }
          }
        }
      }
    }
  }
  > li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    border-radius: $general-border-radius;
    background: #eeeeee;
    box-shadow: $block-shadow;
    padding: 10px;
    position: relative;
    cursor: grab;
    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      height: 0;
      width: 10px;
      content: '';
      transform: translateX(-100%);
      border-bottom: 1px dotted #aaa;
    }
    &.alternative {
      background: #ffffff;
    }
  }
}
.gu-mirror {
  list-style-type: none;
  margin: 10px 0 10px 10px;
  border-radius: $general-border-radius;
  background: #eeeeee;
  box-shadow: $block-shadow;
  padding: 10px;
  position: relative;
  cursor: grabbing;
  &.conditional {
    padding-left: 25px;
    padding-top: 55px;
    background-color: rgba($brand-main-color, 0.15) !important;
    position: relative;
    .condition {
      background-color: $brand-main-color;
      color: white;
      position: relative;
      display: block;
      min-height: 50px;
      margin: -55px -10px 20px -25px;
      line-height: 50px;
      padding-right: 10px;
      border-radius: 0 4px 0 0;
      .ribbon {
        z-index: 2;
        float: left;
        padding: 0 10px;
        margin-right: 15px;
        clear: left;
        position: relative;
        background-color: $brand-info;
        color: white;
        &::after {
          content: '';
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 10px solid $brand-info;
          position: absolute;
          right: -10px;
          top: 0;
        }
      }
    }
  }
}

.tree-list-2 {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  > li {
    list-style-type: none;
    margin: 0 0 0 25px;
    padding: 0 0 10px 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
      &::after {
        position: absolute;
        left: -15px;
        top: -15px;
        height: 44px;
        width: 0;
        content: '';
        transform: translateX(-100%);
        border-left: 1px dotted #aaa;
      }
    }
    &::before {
      position: absolute;
      left: 0;
      top: 29px;
      height: 0;
      width: 15px;
      content: '';
      transform: translateX(-100%);
      border-bottom: 1px dotted #aaa;
      z-index: 0;
    }
    &::after {
      position: absolute;
      left: -15px;
      top: -15px;
      height: 100%;
      width: 0;
      content: '';
      transform: translateX(-100%);
      border-left: 1px dotted #aaa;
      z-index: 0;
    }
    > div {
      border-radius: $general-border-radius;
      background: #eeeeee;
      box-shadow: $block-shadow;
      padding: 10px;
      position: relative;
      z-index: 1;
      &.alternative {
        background: #ffffff;
      }
      .action {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
}

.tree-timeline {
  list-style: none;
  margin-left: 10px;
  padding: 10px 0 0;
  border-left: 2px dotted #aaa;
  &.future {
    padding: 0;
    border-left: 2px dotted #ddd;
    > li {
      background: #c9d3e8;
    }
  }
  > li {
    list-style-type: none;
    margin: 15px 0 15px 25px;
    border-radius: $general-border-radius;
    background: #eeeeee;
    box-shadow: $block-shadow;
    padding: 10px;
    position: relative;
    h4 {
      @media (max-width: $screen-md-max) {
        clear: both;
      }
    }
    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      width: 25px;
      z-index: 0;
      height: 0;
      content: '';
      transform: translateX(-100%);
      border-bottom: 2px dotted #aaa;
    }
    &.toAcknowledge {
      background: linear-gradient(
        to right,
        $brand-complementary-color 0%,
        $brand-main-color 100%
      ) !important;
      color: white;
    }
  }
}
