.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 24px;
  z-index: 4 !important;
}
.bm-burger-bars {
  background: white;
  height: 12% !important;
  border-radius: 3px;
  margin: 4% 0;
}
.bm-morph-shape {
  fill: #373a47;
}

.bm-overlay {
  z-index: 9998 !important;
}
.bm-menu-wrap {
  z-index: 9999 !important;
  .bm-menu {
    background: white;
    .links {
      padding-top: 10px;
      > li {
        > a {
          color: $brand-main-color;
          border-bottom: 5px solid transparent;

          > i {
            color: $brand-main-color;
          }
        }

        > a.active {
          color: white;
          background: $brand-main-color;

          > i {
            color: white;
          }
        }

        > a:hover {
          color: white;
          background: lighten($brand-main-color, 5%) !important;

          > i {
            color: white;
          }
        }
      }
    }
  }
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}
.bm-item-list a {
  padding: 0.8em;
  span {
    margin-left: 10px;
    font-weight: 700;
  }
}
.bm-cross {
  background: $brand-main-color;
}
@media (min-width: $screen-md-min) {
  .bm-burger-button {
    display: none;
  }
}
