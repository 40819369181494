.paper {
  margin: 0 auto rem(20px);
  min-width: 20vw;
  border-radius: 4px;
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.2);
  transition: 0.6s ease;
  background: white;
  padding: 2vh;
  position: relative;
  &:hover {
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .header {
    border-radius: 4px 4px 0 0;
    margin: -2vh -2vh 2vh;
    height: 14vh;
    overflow: hidden;
    background: #cccccc;
    img {
      width: 100%;
      height: auto;
      transform: translateY(-50%);
      opacity: 0.75;
      margin: 7vh 0;
    }
  }
  .actions {
    float: right;
    display: inline-block;
    padding: 6px 0;
  }
  > img {
    max-width: 100%;
    max-height: 100%;
    &.poster {
      border-radius: 4px 4px 0 0;
      margin: -2vh -2vh 2vh;
    }
  }
  hr {
    width: 20%;
    margin: 1vh auto;
    border: 0;
    border-bottom: 1px solid #e6e9ec;
  }
  h1,
  h2,
  h3 {
    > i {
      font-size: inherit;
    }
    .sub {
      padding-left: rem(10px);
      font-size: 66%;
    }
  }
  &.paper-action {
    background-color: #eeeeee;
    &:hover {
      transform: translateY(-2px);
      background-color: #fff;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.19), 0 1px 6px rgba(0, 0, 0, 0.23);
      cursor: pointer;
    }
    > i {
      font-size: $icon-size-large;
      position: absolute;
      top: 50%;
      left: 2vh;
      transform: translateY(-50%);
    }
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      font-size: $font-size-medium;
    }
    .text {
      margin: rem(10px) 0 0;
      font-size: $font-size-normal;
    }
    > a {
      position: absolute;
      top: 50%;
      right: 2vh;
      transform: translateY(-50%);
    }
  }
}
